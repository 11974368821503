import { ga4Push } from '../helper/ga4-utilities';

export class HomeMeasurements {

    /**
     * initializes the home page measurements
     * @param {JQuery} container
     */
    constructor(container) {
        this.container = container;
        this.init();
    }

    init() {
        this.initHomeCarouselMeasurement();
        this.initHomeCategoriesMeasurement();
        this.initHomeDealsMeasurement();
        this.initHomeAccommodationTypeMeasurement();
        this.initHomeAllDealsMeasurement();
    }

    getContainer() {
        if (this.container.length === 0) {
            console.error('Page container not found');
        }

        return this.container;
    }

    /**
     * if there is an ongoing campaign, then we get the name of it from the data tag
     * and send the proper measurement fields
     */
    initHomeCarouselMeasurement() {
        $('#homeCarousel')
        .find('.carousel-link')
        .on('click.clickedOnHero', (event) => {
            const parentItem = $(event.currentTarget).closest('.carousel-item');
            if (parentItem.length === 0) {
                return null;
            }

            const campaignName = $(event.currentTarget).data('campaign-name');
            const nameOfPicture = parentItem.find('img').attr('src');
            const index = Number(parentItem.index('.carousel-item')) + 1;
            const picSuffix = campaignName !== null ? `_${campaignName}` : '';
            const campaignInfix = campaignName !== null ? `- ${campaignName}` : '';

            ga4Push({
                event: 'select_content',
                content_type: `hero_picture${picSuffix}`,
                content_id: `${nameOfPicture}${campaignInfix} - ${index}`,
            });
        });
    }

    initHomeCategoriesMeasurement() {
        this.getContainer()
        .find('.home-categories-swiper')
        .find('.category-link')
        .on('click.homeCategories', (event) => {
            const parentCard = $(event.currentTarget).closest('.js-card');
            if (parentCard.length === 0) {
                return null;
            }
            const nameOfCategory = parentCard.find('.category-card-title').html();
            const index = Number(parentCard.index('.js-card')) + 1;

            ga4Push({
                event: 'select_content',
                content_type: 'popular_categories',
                content_id: `${nameOfCategory} - ${index}`,
            });
        });
    }

    initHomeDealsMeasurement() {
        this.getContainer()
        .find('.home-deals')
        .find('.deal-box-measure-class')
        .on('click.dealBoxClick', (event) => {
            const ga4dataToSend = $(event.currentTarget).data('ga4data');
            if (ga4dataToSend !== null) {
                ga4Push(ga4dataToSend);
            }
        });
    }

    initHomeAccommodationTypeMeasurement() {
        this.getContainer()
        .find('.home-accommodations-swiper')
        .find('.category-link')
        .on('click.accommodationTypeClick', (event) => {
            const parentCard = $(event.currentTarget).closest('.js-card');
            if (parentCard.length === 0) {
                return null;
            }
            const nameOfCategory = parentCard.find('.category-card-title').html();
            const index = Number(parentCard.index('.js-card')) + 1;

            ga4Push({
                event: 'select_content',
                content_type: 'popular_accommodation_type',
                content_id: `${nameOfCategory} - ${index}`,
            });
        });
    }

    initHomeAllDealsMeasurement() {
        this.getContainer()
        .find('.all-deals')
        .on('click.allDealsClick', (event) => {
            ga4Push({
                event: 'select_content',
                content_type: 'summary_link',
                content_id: $(event.target).html(),
            });
        });
    }

}
