import { countdownHelper } from '../helper/countdown.helper';
import { NewsletterComponent } from '../components/newsletter.component';
import { HeaderSearch}  from '../helper/header-search';
import {ga4Push} from '../helper/ga4-utilities';
import {isMobile} from "../helper/is-mobile";

export class Pages {
    /**
     * Init the page
     * @param container {jQuery}
     */
    constructor(container = $()) {
        this.container = container.length === 0 ? $('#content-wrapper') : container;
        if (this.container.length === 0) {
            console.error('Page container not found');
        }
        this.initCountdowns();
        this.newsletter = new NewsletterComponent();
        this.headerSearch = new HeaderSearch();
        this.googlePageInitContainer = undefined;
        this.initPageInitGoogleTagManager();
    }

    /**
     * Return the main container element
     * @return {jQuery}
     */
    getContainer() {
        return this.container;
    }

    /**
     * Init countdowns
     */
    initCountdowns() {
        countdownHelper(this.container);
        const promotionBanner = $('#promotionBanner');
        if (promotionBanner.length > 0) {
            countdownHelper(promotionBanner);
            if (isMobile()) {
                promotionBanner.on('click.promotionBanner', event => {
                    const urlAttribute = $(event.currentTarget).find('.campaign-cta').attr('href');
                    if (urlAttribute.length > 0) {
                        window.location.href = urlAttribute;
                    }
                });
            }
        }
    }

    /**
     * Returns with the header search component
     * @return {HeaderSearch}
     */
    getHeaderSearch() {
        return this.headerSearch;
    }

    /**
     * Returns with the newsletter component
     * @return {NewsletterComponent}
     */
    getNewsletter() {
        return this.newsletter;
    }

    initPageInitGoogleTagManager() {
        const container = this.getGooglePageInitContainer();
        if (container.length > 0) {
            container.find('.page-init-ga4').each((index, element) => {
               const ga4Data = element.dataset.ga4Data;
               if (typeof ga4Data !== 'undefined') {
                   ga4Push({
                       ...JSON.parse(ga4Data)
                   });
               }
            });
        }
    }

    getGooglePageInitContainer() {
        if (typeof this.googlePageInitContainer === 'undefined') {
            this.googlePageInitContainer = $('#googlePageInitContainer');
        }
        return this.googlePageInitContainer;
    }
}
