import { Countdown } from '../components/countdown';

/**
 * Init countdowns in a specific container
 * @param container {jQuery}
 */
export function countdownHelper(container) {
    if (container.length === 0) {
        throw {element: container, reason: 'not found'};
    }

    if (!container instanceof jQuery) {
        throw {element: container, reason: 'not a valid jQuery'};
    }

    container.find('.counter').each((index, item) => {
        const countdown = new Countdown($(item));
        countdown.start();
    });
}
